import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// Styling
import textStyles from "../styles/textStyles"

// Components
import Layout from "../components/Layout"
import SEO from "../components/Seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page not found" />
    <Title>Page not found</Title>
    <Text>
      Hmm, it seems this page doesn't exists. Let's go back and try again from{" "}
      <Link to="/">the start</Link>.
    </Text>
  </Layout>
)

const Title = styled.h1`
  ${textStyles.title};
`

const Text = styled.p`
  margin-bottom: 0;
`

export default NotFoundPage
